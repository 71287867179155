<template>
  <div>
      <v-container :class="$vuetify.breakpoint.mdAndUp ? '': ''">
    <TitleComponent v-if="this.$route.name !== 'Home'"
    class="primary--text yellow lighten-4" :title="`List of Biddings`"/>
    <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="15"
        class="elevation-1"
        dense
    >
    <template #item.Url="{ item }">
        <a target="_blank" :href="$api_url+item.Url">
            {{ item.Name }}
        </a>
    </template>
    <template v-slot:item.PostingDate="{ item }">
      {{ formatDate(item.PostingDate) }}
    </template>
    <template v-slot:item.ClosingDate="{ item }">
      {{ formatDate(item.ClosingDate) }}
    </template>
    </v-data-table>
    </v-container>
  </div>  
</template>

<script>
import axios from 'axios' 
import TitleComponent from '@/components/TitleComponent'
export default {
  name : 'Biddings',
  components : {
    TitleComponent
  },
  data() {
    return {
    items:[], 
    headers:[], 
    query:'',
    }
  },
  mounted(){
    const current = new Date();
    const datetoday = current.getFullYear()+'-'+(current.getMonth()+1)+'-'+current.getDate();
    //alert(datetoday)
    if(this.$route.name !== 'Home'){
      this.query=`/getAllRecords/biddings/where ID > 0/ ClosingDate desc`
    }
    else{
      this.query=`/getAllRecords/biddings/where ClosingDate >= '${datetoday}'/ ClosingDate desc`
    }
    this.getAllRecords(this.query);
    this.headers= [
        {  text: 'Project Title',  value: 'Url',},
        {  text: 'PostingDate',  value: 'PostingDate',},
        {  text: 'ClosingDate',  value: 'ClosingDate', },
    ]
  },  
  methods : {
    
    formatDate:function(value) {
        //alert(value);
        if ( (value !== '0000-00-00') && (value) ) {
          const date = new Date(value)
          return date.toLocaleDateString(['en-US'], {month: 'short', day: '2-digit', year: 'numeric'})  
          //if you want, you can change locale date string
        }
        else{
            if (value==null){
                value='0000-00-00';
            }
            return value
        }
      },  

    getAllRecords:function(query){
      //alert(this.BranchCode); 
      // alert(query)
      axios.get( this.$api_url+query,{
          
        params: {
              
          }
      })
      .then(response => {
          // alert(Object.keys(response.data).length);
          if (Object.keys(response.data).length > 0) {
              // alert(response.data);
              this.items = response.data;
              
            }
      })
      .catch(error => { console.log(error)})
    },  

  }
}
</script>

<style lang="scss" scoped>
  .agency-box {
    padding: 30px 20px;
    background-position: center center;
    background-size: cover;
    cursor: pointer;
    max-height: 264px;
    overflow: hidden;
    height: auto;

    &:hover {
      opacity: 0.9;
      cursor: pointer;
    }

      h1 {
        color: #fff;
        line-height: 1em;
        margin-bottom: 1em;
        max-width: 270px;
      }
  }


</style>